// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-auth-email-confirmation-js": () => import("./../../../src/pages/auth/email-confirmation.js" /* webpackChunkName: "component---src-pages-auth-email-confirmation-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-course-js": () => import("./../../../src/pages/course/[...].js" /* webpackChunkName: "component---src-pages-course-js" */),
  "component---src-pages-course-list-js": () => import("./../../../src/pages/course-list.js" /* webpackChunkName: "component---src-pages-course-list-js" */),
  "component---src-pages-courses-list-js": () => import("./../../../src/pages/courses-list.js" /* webpackChunkName: "component---src-pages-courses-list-js" */),
  "component---src-pages-create-account-js": () => import("./../../../src/pages/create-account.js" /* webpackChunkName: "component---src-pages-create-account-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-datatab-one-month-js": () => import("./../../../src/pages/datatab/one-month.js" /* webpackChunkName: "component---src-pages-datatab-one-month-js" */),
  "component---src-pages-datatab-thirty-six-months-js": () => import("./../../../src/pages/datatab/thirty-six-months.js" /* webpackChunkName: "component---src-pages-datatab-thirty-six-months-js" */),
  "component---src-pages-datatab-three-months-js": () => import("./../../../src/pages/datatab/three-months.js" /* webpackChunkName: "component---src-pages-datatab-three-months-js" */),
  "component---src-pages-datatab-twelve-months-js": () => import("./../../../src/pages/datatab/twelve-months.js" /* webpackChunkName: "component---src-pages-datatab-twelve-months-js" */),
  "component---src-pages-demo-course-js": () => import("./../../../src/pages/demo-course/[...].js" /* webpackChunkName: "component---src-pages-demo-course-js" */),
  "component---src-pages-email-confirm-js": () => import("./../../../src/pages/email-confirm.js" /* webpackChunkName: "component---src-pages-email-confirm-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-generate-certificate-js": () => import("./../../../src/pages/generate-certificate.js" /* webpackChunkName: "component---src-pages-generate-certificate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pdfs-js": () => import("./../../../src/pages/pdfs.js" /* webpackChunkName: "component---src-pages-pdfs-js" */),
  "component---src-pages-quickypedia-js": () => import("./../../../src/pages/quickypedia.js" /* webpackChunkName: "component---src-pages-quickypedia-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-scan-gen-js": () => import("./../../../src/pages/scan-gen.js" /* webpackChunkName: "component---src-pages-scan-gen-js" */),
  "component---src-pages-scan-me-js": () => import("./../../../src/pages/scan-me.js" /* webpackChunkName: "component---src-pages-scan-me-js" */),
  "component---src-pages-student-registration-js": () => import("./../../../src/pages/student-registration.js" /* webpackChunkName: "component---src-pages-student-registration-js" */),
  "component---src-pages-who-are-we-js": () => import("./../../../src/pages/who-are-we.js" /* webpackChunkName: "component---src-pages-who-are-we-js" */),
  "component---src-pages-why-certisured-js": () => import("./../../../src/pages/why-certisured.js" /* webpackChunkName: "component---src-pages-why-certisured-js" */),
  "component---src-templates-bootcamp-pages-js": () => import("./../../../src/templates/bootcampPages.js" /* webpackChunkName: "component---src-templates-bootcamp-pages-js" */),
  "component---src-templates-canva-pages-js": () => import("./../../../src/templates/canvaPages.js" /* webpackChunkName: "component---src-templates-canva-pages-js" */),
  "component---src-templates-coming-soon-js": () => import("./../../../src/templates/comingSoon.js" /* webpackChunkName: "component---src-templates-coming-soon-js" */),
  "component---src-templates-conference-js": () => import("./../../../src/templates/conference.js" /* webpackChunkName: "component---src-templates-conference-js" */),
  "component---src-templates-google-form-pages-js": () => import("./../../../src/templates/googleFormPages.js" /* webpackChunkName: "component---src-templates-google-form-pages-js" */),
  "component---src-templates-landing-pages-js": () => import("./../../../src/templates/landingPages.js" /* webpackChunkName: "component---src-templates-landing-pages-js" */),
  "component---src-templates-quicy-pedia-js": () => import("./../../../src/templates/quicyPedia.js" /* webpackChunkName: "component---src-templates-quicy-pedia-js" */),
  "component---src-templates-single-blog-page-js": () => import("./../../../src/templates/singleBlogPage.js" /* webpackChunkName: "component---src-templates-single-blog-page-js" */),
  "component---src-templates-summer-js": () => import("./../../../src/templates/summer.js" /* webpackChunkName: "component---src-templates-summer-js" */)
}

