import React from "react"
import Layout from "./src/components/layout"
import { Provider } from "react-redux"
import store from "./src/components/app/store/store"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

// expose store when run in Cypress
// expose store when run in Cypress
if (typeof window !== "undefined") {
  if (window.Cypress) {
    window.store = store
  }
}