import { configureStore } from "@reduxjs/toolkit"
import { registerApi } from "../../features/api/authApi"
import authReducer from "../../features/authSlice"
import { combineReducers } from "redux"
import { getTopic, getDetails } from "../../../../utils/cart"

import cartReducer from "../../features/cartSlice"

const rootReducer = combineReducers({
  [registerApi.reducerPath]: registerApi.reducer,
  user: authReducer,
  cart: cartReducer,
})

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(registerApi.middleware),
})

export default store
