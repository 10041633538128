export const cartTotal = (cart) => {
  try {
    const subTotal = cart.reduce(
      (counter, product) => counter + product.price,
      0
    )

    return subTotal
  } catch (err) {}
}

export const cartSubTotal = (cart, taxRate) => {
  try {
    const subTotal = cartTotal(cart)
    const total = subTotal + subTotal * taxRate
    const inrTotal = total.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    })
    return inrTotal
  } catch (err) {}
}

export const getToken = () => {
  try {
    const token = JSON.stringify(localStorage.getItem("token"))
    if (token) {
      return token
    }
  } catch (err) {}
  return ""
}

export const saveTopic = (topic) => {
  localStorage.setItem("topic", JSON.stringify(topic))
}
export const saveDetails = (details) => {
  localStorage.setItem("details", JSON.stringify(details))
}
export const saveNotes = (notes) => {
  localStorage.setItem("notes", JSON.stringify(notes))
}
export const getDetails = () => {
  try {
    const details = JSON.parse(localStorage.getItem("details"))
    if (details) {
      return details
    }
  } catch (err) {}
  return {}
}

export const getTopic = () => {
  try {
    const topic = JSON.parse(localStorage.getItem("topic"))
    if (topic) {
      return topic
    }
  } catch (err) {}
  return {}
}

export const getNotes = () => {
  try {
    const notes = JSON.parse(localStorage.getItem("notes"))
    if (notes) {
      return notes
    }
  } catch (err) {}
  return []
}
// export const removeCart = (strapiId) => {
//   const cart = getCart()
//   const indexOfProduct = cart.findIndex((C) => C.strapiId === strapiId)
//   cart.splice(indexOfProduct, 1)
// }
// export const cartTotal = (cart) => {
//   const subTotal = subTotal(cart)
//   const total = subTotal + subTotal * taxRate
//   return Math.round(total)
// }
