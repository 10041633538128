import { createSlice } from "@reduxjs/toolkit"
import { navigate } from "gatsby"
import { registerApi, addLogin } from "./api/authApi"
import {
  getNotes,
  saveNotes,
  getDetails,
  saveDetails,
  getTopic,
  saveTopic,
} from "../../../utils/cart"

const checkWindow = typeof window !== "undefined"

let authState = {
  username: "",
  token: checkWindow
    ? sessionStorage.getItem("token")
      ? JSON.parse(sessionStorage.getItem("token"))
      : ""
    : null,
  email: "",
  userPro: [],
  notes: getNotes(),
  topic: getTopic(),
  details: getDetails(),
  // notes: checkWindow
  //   ? localStorage.getItem("notes")
  //     ? JSON.parse(localStorage.getItem("notes"))
  //     : []
  //   : null,
  // topic: checkWindow
  //   ? localStorage.getItem("topic")
  //     ? JSON.parse(localStorage.getItem("topic"))
  //     : {}
  //   : null,
  // details: checkWindow
  //   ? localStorage.getItem("details")
  //     ? JSON.parse(localStorage.getItem("details"))
  //     : {}
  //   : null,
}

export const authSlice = createSlice({
  name: "auth",
  initialState: authState,
  reducers: {
    addTopic(state, action) {
      console.log("payload", action.payload.copy)
      Object.assign(state.topic, action.payload.copy)
      saveTopic(action.payload.copy)
    },
    addDetails(state, action) {
      console.log("details", action.payload)
      Object.assign(state.details, action.payload.copy)
      saveDetails(action.payload.copy)
    },
    addNotes(state, action) {
      state.notes.push(action.payload.copy)
      saveNotes(action.payload.copy)
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      registerApi.endpoints.addLogin.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.jwt
        state.username = payload.user.username
        state.email = payload.user.email
      }
    )
    builder.addMatcher(
      registerApi.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        console.log("payload", payload)
        saveTopic(payload.length ? payload[0]?.userLastWatched : {})
        saveDetails(payload.length ? payload[0]?.topicDetails : {})
        saveNotes(payload.length ? payload[0]?.userNotes : [])
      }
    )
  },
})

export const { addTopic, addDetails, addNotes } = authSlice.actions

export default authSlice.reducer
