import * as React from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Popper from "@mui/material/Popper"
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state"
import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import styled from "styled-components"
import { Box, ClickAwayListener } from "@mui/material"
import { style } from "@mui/system"
import DropDownItems from "./DropDownItems"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
export default function PopperPopupState(props) {
  const {data, toggleNav} = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [arrowRef, setArrowRef] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined

  return (
    <Wrapper>
      <Button variant="contained" onClick={handleClick}>
        {data[0].navbar_title ? data[0].navbar_title : 'Courses'} {` `} {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </Button>
      <Popper
        placement="bottom"
        id={id}
        disablePortal={true}
        open={open}
        anchorEl={anchorEl}
        sx={{
          marginTop: "10px !important",
          paddingRight: "10px",
          zIndex: "99"
        }}
        modifiers={[
          {
            name: "flip",
            enabled: false,
            options: {
              altBoundary: false,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: false,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClick}>
          <SPaper>
            <span className="arrow" ref={setArrowRef} />
            <DropDownItems data={data[0].dropDownItems || data[0].assessment_links} handleClick={handleClick} toggleNav={toggleNav} />
{/* 
            {data[0].courses_links.map((d) => (
              <DropDownItems title={d.title} />
            ))} */}
          </SPaper>
        </ClickAwayListener>
        {/* <Box component="span" className="arrow" ref={setArrowRef} /> */}
      </Popper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  button {
    outline: none;
    box-shadow: none;
    background: none;
    border: none;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: inherit;
    font-family: var(--family) !important;
    text-transform: lowercase;
    margin-left: 2rem;
    padding: 0;
    &:hover {
      background-color: none;
      background: none !important;
      box-shadow: none;
    }
    @media (max-width: 991px) {
      margin-top: 2rem;
      margin-left: 0;
    }
  }
`
const SPaper = styled(Paper)`
  background-color: #f1f1f1 !important;
  .MuiAccordionSummary-root .Mui-expanded{
    min-width: 100px;
  }
  .arrow {
    position: absolute;
    width: 15px;
    height: 15px;
    top: -5px;
    box-sizing: border-box;

    &::before {
      content: "";
      margin: auto;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #f1f1f1;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
      transform: rotate(45deg);
      @media (max-width: 991px) {
        background-color: #fff;
    }
    }
  }
`

// const SMenu = styled(Menu)`
//   .MuiPaper-root {
//     @media (max-width: 991px) {
//       width: 100% !important;
//       background-color: #f1f1f1 !important;
//     }
//   }
//   .MuiPopover-paper:before {
//     @media (max-width: 991px) {
//       right: 115px !important;
//     }
//   }
//   ul {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     border-radius: 6px;
//     @media (max-width: 991px) {
//       max-height: 300px;
//       overflow-y: auto;
//     }
//   }
//   li {
//     padding: 0.5rem 1rem;
//     width: 100%;
//     display: flex;
//     justify-content: flex-start;
//   }
//   a {
//     text-decoration: none;
//     color: var(--secondaryColor);
//     font-weight: 500;
//   }
// `
