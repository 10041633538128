import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import GlobalStyles from "../styles/GlobalStyles"
import TypographyStyles from "../styles/TypographyStyles"
import Analogo from "../assets/Analogica.svg"
import FooterLogo from "../images/certisured_final_footer.png"
import Helmet from "react-helmet"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import FloatingWhatsApp from "react-floating-whatsapp"
import icon from "../images/favicon.png"
import { useSelector } from "react-redux"
import "./layout.css"
import { Link } from "gatsby"
import AnnouncementBar from "./AnnouncementBar"

function Layout(props) {
  const token = useSelector((state) => state.user.token)

  return (
    <>
      <Helmet>
        <script
          defer
          type="module"
          src="https://cdn.jsdelivr.net/npm/@justinribeiro/lite-youtube@0.6.2/lite-youtube.js"
        ></script>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=block"
          rel="stylesheet"
        ></link>
      </Helmet>

      <GlobalStyles />
      <TypographyStyles />
      {props.location.pathname.match("/dashboard/*") ||
      props.location.pathname.match("/app/*") ? null : (
        <Navbar Logo={<Analogo className="ana-img"/>} />
      )}
      <div className="layout">{props.children}</div>
      <Footer Logo={FooterLogo} />
      <FloatingWhatsApp
        phoneNumber="+919606698866"
        accountName="Certisured"
        statusMessage="Our executives are ready to help you"
        chatMessage="We are just a message away. We are here to help you."
        notificationSound={true}
        avatar={icon}
        className="whats-app-widget"
      />
    </>
  )
}

export default Layout
