import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import "isomorphic-fetch"
import store from "../../app/store/store"

export const registerApi = createApi({
  reducerPath: "registerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.STRAPI_API_URL}/`,
    prepareHeaders: (headers) => {
      console.log(store.getState().user.token)
      const token = store.getState().user.token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: [`User`],
  endpoints: (builder) => ({
    addRegister: builder.mutation({
      query: (user) => ({
        url: "/auth/local/register",
        method: "POST",
        body: user,
      }),
    }),
    addLogin: builder.mutation({
      query: (credentials) => ({
        url: "/auth/local",
        method: "POST",
        body: credentials,
      }),
    }),
    addUser: builder.mutation({
      query: (credentials) => ({
        url: "/userprofiles",
        method: "POST",
        body: credentials,
      }),
    }),
    addUserPut: builder.mutation({
      query: ({ credentials, kId }) => ({
        url: `/userprofiles/${kId}`,
        method: "PUT",
        body: credentials,
        providerTags: ["User"],
      }),
    }),
    getCourses: builder.query({
      query: (slice) => `/courses${slice}`,
      providerTags: ["User"],
    }),
    getFreeCourseLms: builder.query({
      query: (slice) => `/free-courses-lms${slice}`,
      providerTags: ["User"],
    }),
    getUser: builder.query({
      query: () => "/userprofiles",
      providerTags: ["User"],
    }),
    getUserDetails: builder.query({
      query: () => "/users/me",
    }),
    getOrders: builder.query({
      query: () => "/orders",
    }),
    getFreeCourse: builder.query({
      query: () => "/add-free-demo-course-descriptions",
    }),
    getPdfs: builder.query({
      query: () => "/addpdfs"
    }),
    getCertificates: builder.query({
      query: () => "/certificates"
    })
  }),
})

export const {
  useAddRegisterMutation,
  useAddLoginMutation,
  useGetUserQuery,
  useGetUserDetailsQuery,
  useGetOrdersQuery,
  useGetFreeCourseQuery,
  useAddUserMutation,
  useAddUserPutMutation,
  useGetCoursesQuery,
  useGetFreeCourseLmsQuery,
  useGetPdfsQuery,
  useGetCertificatesQuery
} = registerApi
