const link = [
  {
    text: "courses",
  },
  {
    path: "/why-certisured",
    text: "why certisured",
    class: "courses",
  },
  {
    path: "/blogs",
    text: "blogs",
  },
  {
    path: "/quickypedia",
    text: "quickypedia",
  },
  {
    path: "/events",
    text: "events",
  },
]
export default link
