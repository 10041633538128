import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import styled from "styled-components"
import { Link } from "gatsby"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
export default function ControlledAccordions(props) {
  const { data, handleClick, toggleNav } = props
  const defaultOpen = data[0].title
  console.log("defaultOpen", defaultOpen)
  const [expanded, setExpanded] = React.useState(defaultOpen)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  const handleClose = () => {
    handleClick()
    toggleNav()
  }

  return (
    <Wrapper>
      {data.map((d) => (
        <Accordion
          expanded={expanded === `${d.title}`}
          onChange={handleChange(`${d.title}`)}
        >
          <SAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              {d.title}
            </Typography>
          </SAccordionSummary>
          <SAccordionStyles sx={{ display: "flex", flexDirection: "column" }}>
            {(d.course_links || d.dropDownItems).map((c) => (
              <div className="links_div">
                <Link to={`/${c.link_to}`} onClick={handleClose}>
                  {c.title}
                </Link>
                <Link to={`/${c.link_to}`} onClick={handleClose}>
                <ArrowForwardIcon />
                </Link>
              </div>
            ))}
          </SAccordionStyles>
        </Accordion>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  p {
    width: 100%;
  }
`
const SAccordionStyles = styled(AccordionDetails)`
  background: #f1f1f1 !important;
  display: flex;
  flex-direction: column;
  padding: 12px 0 !important;
  .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
  @media (max-width: 991px) {
    padding: 8px 16px 16px !important;
  }
  .links_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
  }
  a {
    text-decoration: none;
    color: var(--secondaryColor);
    padding: 5px 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    @media (max-width: 991px) {
      font-size: 0.9rem;
    }
  }
`
const SAccordionSummary = styled(AccordionSummary)`
  background: #f1f1f1 !important;
  box-shadow: 0px 1.45516px 4.36547px rgb(0 0 0 / 25%);
  p {
    font-weight: 500;
    font-size: 0.9rem;
  }
`
