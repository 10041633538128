import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight"
import { IconButton } from "@mui/material"

const Wrapper = styled.div`
  width: 100%;
  height: 40px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(11, 1fr);

  @media (max-width: 767px) {
    height: auto;
  }
  .main {
    grid-area: auto/1/auto/12;
    display: grid;
    grid-template-columns: inherit;
    background-color: var(--thirdColor);
  }
  .announcement-bar-content {
    grid-area: auto/2/auto/11;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    overflow: hidden;
    text-decoration: none;
    color: var(--secondaryColor);
    @media (max-width: 767px) {
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .inside-bar-content {
    display: flex;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    width: 85%;

    @media (max-width: 767px) {
      width: 100%;
    }

    p {
      font-size: 0.9375em;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 767px) {
        white-space: unset;
        overflow: hidden;
        text-overflow: unset;
        text-align: center;
      }
    }
    a {
      margin: 0;
      padding: 0;

      text-decoration: none;
    }
  }
  .arrow-keys {
    display: flex;
  }
`
const announce = [
  {
    id: 1,
    title:
      "Announcement of new blog Outliers are not liars: they have a significance in peripheral vision for business leaders",
    link: "/",
  },
  {
    id: 2,
    title: "New course has been added Diploma in Machine Learning Engineering",
    link: "/",
  },
  {
    id: 3,
    title:
      "Register for Scientific & Academic writing before Dec 4th and Get 33% off! Used Code : MOUANA2021 ",
    link: "/",
  },
]
function AnnouncementBar() {
  const [index, setIndex] = React.useState(1)

  const handleLeftClick = () => {
    if (index !== 0) {
      setIndex(index - 1)
    }
  }
  const handleRightClick = () => {
    if (index !== announce.length - 1) {
      setIndex(index + 1)
    }
  }
  return (
    <Wrapper>
      <div className="main">
        <Link to="/" className="announcement-bar-content">
          <div className="inside-bar-content">
            <p>{announce[index].title}</p>
          </div>
          <div className="arrow-keys">
            <div className="left-arrow">
              <IconButton onClick={handleLeftClick} disabled={index === 0}>
                <ArrowCircleLeftIcon />
              </IconButton>
            </div>
            <div className="right-arrow">
              <IconButton
                onClick={handleRightClick}
                disabled={index === announce.length - 1}
              >
                <ArrowCircleRightIcon />
              </IconButton>
            </div>
          </div>
        </Link>
      </div>
    </Wrapper>
  )
}

export default AnnouncementBar
